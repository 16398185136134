*, *::before, *::after {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pokesheet{
  width: clamp(250px,100%, 400px);
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  column-gap: 12px;
  @media screen and (max-width: 812px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    // width: clamp(250px,100%, 400px);
  }
  @media screen and (max-width: 610px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    width: clamp(250px,100%, 400px);
  }
}

.input-container{
  display: grid;
  width: 100%;
  grid-template-columns: 40px 1fr 1fr 1fr;
  gap:6px;
  margin: 12px 6px;
  align-items: center;
  width: clamp(250px,100%, 400px);
  &.pokesheet-header{
    font-weight: bold;
    font-size: 18px;
  }
  .raid-boss{
    width: 100%;
    grid-column: 1 / 3;
  }
  .pokemaster{
    width: auto;
    justify-self: center;
  }
  input{
    width: 100%;
    position: relative;
    font-size: 16px;
    padding: 6px;
  }
  .input-dmg-container{
    width: auto;
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 6px;
    padding: 6px;
    align-items: center;
    &.boosted{
      background-color: rgb(78, 84, 255);
    }
    &.clear{
      background-color: rgb(122, 231, 122);
    }
    &.solo{
      background-color: rgb(122, 202, 231);
    }
  }
  img{
    transform: scale(1.1);
    width: 45px;
  }
}

.button-container{
  display: flex;
  justify-content: center;
  @media screen and (max-width: 900px) {
    position: fixed;
    bottom: 0;
    left:50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: #00ABF1;
    padding: 24px;
    padding-top: 18px;
    gap: 24px;
  }
}

.submit{
  margin: 24px;
  margin-top: 0px;
  font-size: 24px;
  background: #0c1c3e;
  color: white;
  padding: 12px 36px;
  border-radius: 24px;
  border: none;
  cursor: pointer;
  @media screen and (max-width: 900px) {
    margin: 0px;
  }
}

.button-buffer{
  @media screen and (max-width: 900px) {
    height: 100px;
  }
}

.weather-boost-container{
  display: flex;
  gap: 12px;
  align-items: center;
}

.weather-boost{
  font-size: 1.2rem;
  font-weight: bold;
}

.weather-boost-toggle{
  background: gray;
  height: 50px;
  width: 100px;
  border-radius: 500px;
  position: relative;
  cursor: pointer;
  transform: scale(.8);
  &::before{
    position: absolute;
    content:"";
    background: rgb(164, 164, 255);
    height: 100%;
    width: 100%;
    border-radius: 500px;
    transition: opacity .4s ease-out;
    opacity: 0;
    z-index: 1;
  }
  .weather-boost-toggle-shape{
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    top: 50%;
    left: 5px;
    transform: translate(0%, -50%);
    transition: transform .4s ease-out;
    background-color:blue;
    background-image: url("./icons/raincloud.png");
    background-size: 80%;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 2;
    &::before{
      position: absolute;
      content:"";
      background: white;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      transition: opacity .4s ease-out;
      opacity: 1;
      z-index: -1;
    }
  }
  &.boosted{
    &::before{
      opacity: .75;
    }
    .weather-boost-toggle-shape{
      transform: translate(125%,-50%);
      background-color:blue;
      &::before{
        opacity: .25;
      }
    }
  }
}